<!--  -->
<template>
  <div class="main-banner">
    <div class="inner">
      <!--轮播-->
      <swiper :options="swiperOption" ref="mySwiper">
        <swiper-slide
          :style=" {'background': 'url(' + require('../../../assets/img/banner-001.png') + ') center no-repeat','background-size': 'cover'}"
        >
          <div class="txt">
            <p class="title">业务积木化开发 省事省力</p>
            <p class="des">通过业务模块库查找系统已有的业务模块，直接将其复制出来即可进行使用，对应的后端逻辑服务完全无需修改</p>
            <div class="btn" @click="jump('http://demo.icuapi.com')">立即体验</div>
          </div>
        </swiper-slide>
        <swiper-slide
          :style=" {'background': 'url(' + require('../../../assets/img/banner-002.png') + ') center no-repeat','background-size': 'cover'}"
        >
          <div class="txt">
            <p class="title">满足开发者的多样化需求</p>
            <p class="des">软件公共部分无需开发，根据针对的不同业务，选择是针对企业内部或针对C端用户</p>
            <div class="btn" @click="jump('http://demo.icuapi.com')">立即体验</div>
          </div>
        </swiper-slide>
        <swiper-slide
          :style=" {'background': 'url(' + require('../../../assets/img/banner-003.png') + ') center no-repeat','background-size': 'cover'}"
        >
          <div class="txt">
            <p class="title">速度快且灵活的开发框架</p>
            <p class="des">强大的代码生成器，多组件、多模块，拿来即用</p>
          </div>
        </swiper-slide>
        <swiper-slide
          :style=" {'background': 'url(' + require('../../../assets/img/banner-004.png') + ') center no-repeat','background-size': 'cover'}"
        >
          <div class="txt">
            <p class="title">标准接口对接能力</p>
            <p class="des">平台提供统一的规则和规范，用于对外提供API和其他系统进行集成，保证了系统的安全性和规范性</p>
            <div class="btn" @click="jump('http://demo.icuapi.com')">立即体验</div>
          </div>
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination"></div>
        <div class="swiper-button-prev swiper-button-white isPc" slot="button-prev"></div>
        <div class="swiper-button-next swiper-button-white isPc" slot="button-next"></div>
      </swiper>
    </div>
  </div>
</template>

<script>
//引入swiper
import mixin from '@/mixin'
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
export default {
  mixins: [mixin],
  data () {
    return {
      swiperOption: {
        loop: true,
        autoplay: 5000,
        // 显示分页
        pagination: ".swiper-pagination",
        paginationClickable: true,
        // 设置点击箭头
        nextButton: ".swiper-button-next",
        prevButton: ".swiper-button-prev",
        effect: 'fade',
        autoplayDisableOnInteraction : false,//点击后还是可以自动轮播
        fadeEffect: {
          crossFade: true,
        }
      }
    }
  },
  components: {
    Swiper,
    SwiperSlide
  },
  computed: {
    swiper () {
      return this.$refs.mySwiper.swiper;
    }
  },
  mounted () {
    // current swiper instance
    // 然后你就可以使用当前上下文内的swiper对象去做你想做的事了
    this.swiper.slideTo(3, 1000, false);
  },
  methods: {

  }
}

</script>
<style lang='scss' scoped>
.main-banner {
  width: 100%;
}
.inner {
  width: 100%;
  height: 630px;
  @media (max-width: 750px) {
    height: 415px;
  }
}
.inner .swiper-container {
  height: 630px;
  @media (max-width: 750px) {
    height: 415px;
  }
}
.inner .swiper-container .swiper-slide {
  width: 100%;
  line-height: 630px;
  color: #000;
  font-size: 16px;
  text-align: center;
  position: relative;
}
.inner .swiper-button-prev,
.swiper-button-next {
  height: 23px;
  width: 23px;
  background-size: contain;
}
.inner .swiper-button-next {
  right: 30px;
  background-image: url(../../../assets/img/right-001.png);
  &:hover {
    background-image: url(../../../assets/img/right-002.png);
  }
}
.inner .swiper-button-prev {
  left: 30px;
  background-image: url(../../../assets/img/left-001.png);
  &:hover {
    background-image: url(../../../assets/img/left-002.png);
  }
}
.inner ::v-deep .swiper-pagination-bullet {
  background: #fff;
}
.inner ::v-deep .swiper-pagination-bullet-active {
  background: #fff;
}
.inner .txt {
  // position: absolute;
  // left: 50%;
  // top: 50%;
  // transform: translateX(-50%) translateY(-22%);
  padding: 0 2rem 0 10rem;
  margin: 220px auto 0;
  max-width: 1500px;
  height: 400px;
  text-align: left;
  line-height: 1;
  color: #fff;
  @media (max-width: 750px) {
    margin-top: 110px;
    padding-left: 2rem;
  }
  .title {
    margin-bottom: 25px;
    font-size: 32px;
    letter-spacing: 1.5px;
  }
  .des {
    max-width: 500px;
    line-height: 2;
    font-size: 17px;
    letter-spacing: 1px;
    margin-bottom: 10px;
  }
  .btn {
    width: 140px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    border: 1px solid #fff;
    position: relative;
    overflow: hidden;
  }
  .btn::after {
    content: '';
    display: block;
    position: absolute;
    top: -36px;
    left: -100px;
    background: white;
    width: 50px;
    height: 125px;
    opacity: 20%;
    transform: rotate(-45deg);
  }

  .btn:hover::after {
    left: 120%;
    transition: all 600ms cubic-bezier(0.3, 1, 0.2, 1);
    -webkit-transition: all 600ms cubic-bezier(0.3, 1, 0.2, 1);
  }
}
</style>
