<!--  -->
<template>
  <div ref="box" style="height: 100%;overflow:hidden">
    <pHeader :base="base" position="fixed" />
    <pBanner />
    <div class="content">
      <div class="inner">
        <div class="header-title">
          <div class="tit wow animated slideInLeft">框架版本</div>
          <p class="tips wow animated slideInRight">开源版Apache2.0开源协议</p>
        </div>
        <div class="service layout-flex">
          <div class="item wow animated slideInRight" v-for="(item,index) in demo" :key="index">
            <div class="top">
              <h4>{{ item.name }}</h4>
              <!--              <p v-html="item.info"></p>-->
            </div>
            <div class="bottom">
              <div class="price">
                <span :class="item.type==2?'red':''">
                  {{item.price}}
                  <span v-if="item.type==2">{{item.oldPrice}}</span>
                </span>
                <span>
                  <!--                  <span>/年</span>-->
                </span>
              </div>
              <ul class="list">
                <li>基于 Spring Boot 、Shiro、JWT</li>
                <li>支持按钮级别权限粒度，可自定义数据权限</li>
                <li>完善的 XSS 防范及脚本过滤，彻底杜绝 XSS 攻击</li>
                <li>成熟的一键生成功能（包括控制器、模型、视图、菜单等）</li>
                <li>文档使用的 knife4j 进行了增强显示</li>
                <li>前端基于 vue 实现，封装 element-ui，快速上手</li>
                <li>集成 ckeditor 实现富文本编辑器增强</li>
                <li>提供H5端代码 基于uniapp多移动端支持</li>
                <li>完善的API对外方案 对接开放更安全</li>
                <li>逻辑完善符合产品定位</li>
              </ul>

              <div class="btn-group">
                <div class="buy" @click="jump('https://gitee.com/hcwdc/castle-fortress')" v-if="item.type==1">下载程序源码</div>
                <div class="buy" @click="jump('http://demo.icuapi.com')" v-if="item.type==1">立即在线体验</div>
              </div>
<!--              <div-->
<!--                class="buy"-->
<!--                @click="$router.push('/payfor/index?id='+item.id)"-->
<!--                v-if="item.type==2"-->
<!--              >购买授权</div>-->
            </div>
          </div>
        </div>
        <div class="header-title">
          <div class="tit wow animated slideInLeft">选择我们的优势</div>
          <p class="tips wow animated slideInRight">安全、稳定、持续更新的基础平台与解决方案，避免重复造轮子，专注于业务逻辑</p>
        </div>
        <div class="goods layout-flex">
          <div class="item wow animated zoomIn" v-for="(item,index) in 6" :key="index">
            <div class="top">
              <img src="@/assets/img/advantage_001.png" alt v-if="index==0" />
              <img src="@/assets/img/advantage_002.png" alt v-if="index==1" />
              <img src="@/assets/img/advantage_003.png" alt v-if="index==2" />
              <img src="@/assets/img/advantage_004.png" alt v-if="index==3" />
              <img src="@/assets/img/advantage_005.png" alt v-if="index==4" />
              <img src="@/assets/img/advantage_006.png" alt v-if="index==5" />
            </div>
            <div class="bot" v-if="index==0">
              <h4>全源码开源</h4>
              <p>可二次开发的全源码交付</p>
            </div>
            <div class="bot" v-if="index==1">
              <h4>专业技术咨询</h4>
              <p>远程技术支持服务<br/>(需额外付费)</p>
            </div>
            <div class="bot" v-if="index==2">
              <h4>提高开发效率</h4>
              <p>组件与业务拿来即用</p>
            </div>
            <div class="bot" v-if="index==3">
              <h4>降低项目成本</h4>
              <p>符合业务场景省90%代码开发</p>
            </div>
            <div class="bot" v-if="index==4">
              <h4>采用最新技术</h4>
              <p>java技术栈 前后端分离</p>
            </div>
            <div class="bot" v-if="index==5">
              <h4>云原生架构</h4>
              <p>大量使用云原生技术</p>
            </div>
          </div>
        </div>
      </div>
      <div class="fast">
        <div class="inner">
          <div class="header-title">
            <div class="tit wow animated slideInLeft">核心功能助力快速开发</div>
            <p class="tips wow animated slideInRight">2大核心功能帮助开发者更快的进行业务开发</p>
          </div>
          <div class="fast-inner">
            <div class="item">
              <div class="box wow animated zoomIn">
                <div class="img"></div>
                <p class="t">组件库</p>
                <p class="des">
                  我们将很多业务常见的组件做了独立的封装，开发者在
                  使用过程中查询文档进行调用即可，无需再次自己封装
                  组件，传入自己的数据即可满足需求
                </p>
              </div>
            </div>
            <div class="item">
              <div class="box wow animated zoomIn">
                <div class="img"></div>
                <p class="t">业务模块库</p>
                <p class="des">
                  已有20+个流程完善的业务逻辑，只要符合业务逻辑，从
                  库里面直接复制出来即可进行使用，所有模块可拆可组，
                  开发者只需自己开发个性化定制的地方即可
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="Power">
        <div class="inner">
          <div class="header-title">
            <div class="tit wow animated slideInLeft">易用简单的基础功能助力</div>
            <p class="tips wow animated slideInRight">系统自带的基础功能，如系统权限、组织架构、系统管理等功能无需开发，直接使用</p>
          </div>
          <img src="@/assets/img/Basic functions-001.png" alt />
        </div>
      </div>
      <div class="introduce">
        <div class="inner">
          <div class="header-title">
            <div class="tit wow animated slideInLeft">技术框架介绍</div>
          </div>
          <img src="@/assets/img/Technical framework.png" alt />
        </div>
      </div>
      <div class="result wow animated slideInDown">
        <div class="type">
          <p>基于云原生技术的业务开发框架</p>
            <div class="play" @click="jump('http://demo.icuapi.com')" >立即体验</div>
        </div>
      </div>
    </div>
    <pFooter />
  </div>
</template>

<script>
import mixin from "@/mixin"
import pBanner from "@/components/pc/p-banner/p-banner"
import { getFrameList, loginByDomain } from "@/api";

export default {
  mixins: [mixin],
  data () {

    return {
      demo: [
          { name: '开源版', oldPrice: '0.01', price: "免费开源", type: 1 },
        // { name: '企业版', oldPrice: '￥5999', price: "￥4999", type: 2, id: '1419936280676868097' }
      ],
      frameList: [],
      base: true,
      serialNum: '' //流水号
    };
  },

  components: {
    pBanner,
  },

  computed: {},
  created () {
    //默认获取三个
    getFrameList({
      currentPage: 1,
      size: 3,
    }).then(res => {
      console.log(res)
      this.frameList = res.data.records
      console.log(this.frameList)
      // res.forEach(item=>{
      //   this.api.push(item);
      // })
    })
    //登录完返回的这个页面。拿着流水号去登录。
    this.serialNum = this.$route.query.serialNum
    if (this.serialNum) {
      loginByDomain({ serialNum: this.serialNum }).then(res => {
        if (res.code == 0) {
          console.log(res)

          this.serialNum = '' //置空。。免得刷新多次请求。
          localStorage.setItem("token", res.data.token)
          localStorage.setItem("userInfo", JSON.stringify(res.data.userInfo))
          localStorage.setItem("fortressRefreshToken", res.data.refreshToken)
          localStorage.setItem("fortressExpiredTime", res.data.expiredTime)
          //刷新一下，显示头像等信息
          let fullPath = this.$route.fullPath
          //不论有没有参数。去除 serialNum 参数。。避免跳转回来时两个serialNum参数会报错
          fullPath = fullPath.replaceAll(/[?&]serialNum=[^&]*&?/g, '&')
          //如果serialNum 是最后一个参数，会出现 最后一个符号是& 如果有 切掉
          fullPath = fullPath.lastIndexOf('&') == (fullPath.length - 1) ? fullPath.substring(0, fullPath.length - 1) : fullPath;
          window.location.href = "http://frame.icuapi.com" + fullPath;

        }
      })
    }

  },
  mounted () {

    this.$nextTick(() => {
      // 添加滚动事件
      window.addEventListener('scroll', this.handleScroll)

    })
    // let box = this.$refs.box
    // box.addEventListener('scroll', () => {
    //   this.handleScroll()
    // }, false)
  },
  methods: {

    handleScroll () {
      // 这是一个示例代码，打印出监听滚动的组件滚动距离
      var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      let box = this.$refs.box.children[0].offsetHeight
      if (scrollTop > (box / 2)) {
        this.base = false
      }
      else {
        this.base = true
      }
    }
  }
}

</script>
<style lang='scss' scoped>
.content {
  padding: 10rem 0 0;
  .inner {
    max-width: $minWidth;
    margin: 0 auto 10rem;

    .service {
      justify-content: center;
      margin-bottom: 10rem;
      .item {
        padding: 0;
        width: 74rem;
        border-radius: 2rem;
        overflow: hidden;
        box-shadow: 3px 3px 10px 0 rgba(0,0,0,.15);
        &:not(:last-child) {
          margin-right: 6rem;
        }
        // &:nth-of-type(2) {
        //   .top {
        //     background: #1573f0;
        //   }
        //   .bottom {
        //     .list {
        //       li {
        //         &:nth-of-type(1),
        //         &:nth-of-type(2) {
        //           color: #1573f0;
        //           &::before {
        //             background: #1573f0;
        //           }
        //         }
        //       }
        //     }
        //     .buy {
        //       color: #1573f0;
        //       border: 1px solid #1573f0;
        //       &:hover {
        //         background: #1573f0;
        //         color: #fff;
        //       }
        //     }
        //   }
        // }
        .btn-group{
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .buy{
          margin: 10px;
        }
        &:nth-of-type(2) {
          .top {
            background-image: linear-gradient(to right, #ffc032, #fa9723);
          }
          .bottom {
            .list {
              li {
                &:nth-of-type(1),
                &:nth-of-type(2) {
                  color: #fa9b24;
                  &::before {
                    background: #fa9b24;
                  }
                }
              }
            }

            .buy {
              color: #fa9b24;
              border: 1px solid #fa9b24;

              &:hover {
                background: #fa9b24;
                color: #fff;
              }
            }
          }
        }
        .top {
          padding: 4rem 2rem;
          text-align: center;
          width: 100%;
          height: 14rem;
          color: #fff;
          background-image: linear-gradient(to right, #407bff, #36a4ff);
          h4 {
            margin-bottom: 0.8rem;
            font-size: 2.2rem;
          }
          p {
            @include show_line(1);
            font-size: 1.4rem;
          }
        }
        .bottom {
          padding: 2rem 4rem;
          border: 2px solid #f6f6f6;
          border-top: none;
          border-bottom-right-radius: 2rem;
          border-bottom-left-radius: 2rem;
          .price {
            margin-bottom: 2.5rem;
            text-align: center;
            font-size: 2.8rem;
            span {
              font-size: 2.4rem;
              span {
                font-size: 2rem;
              }
            }
            .red {
              color: red;
              & > span {
                color: #999;
                font-size: 1.5rem;
                text-decoration: line-through;
              }
            }
          }
          .list {
            margin-bottom: 4rem;
            li {
              padding-left: 1.5rem;
              font-size: 1.6rem;
              margin-bottom: 1rem;
              position: relative;
              &:nth-of-type(1),
              &:nth-of-type(2) {
                color: #409eff;
                &::before {
                  background: #409eff;
                }
              }
              &:before {
                content: '';
                position: absolute;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
                width: 0.6rem;
                height: 0.6rem;
                background: #333;
                border-radius: 50%;
              }
            }
          }
          .buy {
            font-size: 1.4rem;
            margin: 0 10px;
            width: 16rem;
            height: 4rem;
            line-height: 4rem;
            text-align: center;
            color: #409eff;
            border: 1px solid #409eff;
            cursor: pointer;
            &:hover {
              background: #409eff;
              color: #fff;
            }
          }
        }
      }
    }
    .goods {
      .item {
        margin-top: 0;
        // width: 26.8rem;
        border: 1px solid #f7fafe;
        transition: all 0.5s;
        cursor: pointer;
        &:not(:last-child) {
          margin-right: 3rem;
        }
        .top {
          width: 100%;
          // height: 18rem;
          overflow: hidden;

          img {
            width: 100%;
          }
        }
        .bot {
          padding: 2.5rem 1rem;
          width: 100%;
          height: 10rem;
          text-align: center;
          box-shadow: 1px 1px 10px 5px #f7fafe;

          h4 {
            margin-bottom: 0.8rem;
            font-size: 1.8rem;
            font-weight: normal;
            color: #181818;
          }
          p {
            font-size: 1.4rem;
            color: #666666;
          }
        }
        &:hover {
          margin-top: -1.5rem;
          box-sizing: 2px 4px 10px 0 rgb(18 113 239 / 6%);
          // .top {
          //   width: 100%;
          //   img {
          //     transform: scale(1.1);
          //   }
          // }
          // .bot {
          //   box-shadow: 1px 1px 25px 5px #f7fafe;
          // }
        }
      }
    }
  }
  .result {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 24.8rem;
    text-align: center;
    background-image: url('../../../../assets/img/result_bg.png');
    background-size: 100% 100%;
    .type {
      p {
        margin-bottom: 4.5rem;
        font-size: 2.2rem;
        color: #fff;
        letter-spacing: 1.5px;
      }
      .play {
        margin: 0 auto;
        width: 24rem;
        height: 4.5rem;
        line-height: 4.5rem;
        color: #1e81fb;
        font-size: 1.6rem;
        background: #fff;
        border-radius: 20rem;
        cursor: pointer;
        font-weight: bold;
      }
    }
  }
  .fast {
    padding: 7rem;
    width: 100%;
    background: #f3f9ff;
    .inner {
      margin-bottom: 0;
      .fast-inner {
        display: flex;
        width: 100%;
        .item {
          width: 50%;
          .box {
            padding: 6rem;
            width: 90%;
            margin: 0 auto;
            text-align: center;
            background: #fff;
            border: 1px solid #e9e9e9;
            cursor: pointer;
            .img {
              width: 10.5rem;
              height: 10.5rem;
            }
            .t {
              margin-bottom: 1.5rem;
              font-weight: bold;
              font-size: 1.8rem;
              color: #404750;
            }
            .des {
              line-height: 1.6;
              color: #adafb1;
              font-size: 1.6rem;
            }
            &:hover {
              color: #fff;
              background: #0389fe;
              .t {
                color: #fff;
              }
              .des {
                color: #fff;
              }
            }
          }
          &:first-child {
            .box {
              .img {
                margin: 0 auto;
                background: url(../../../../assets/img/Core-functions_002.png);
                background-size: 100% 100%;
              }
              &:hover {
                border-color: none;
                .img {
                  background: url(../../../../assets/img/Core-functions_001.png);
                  background-size: 100% 100%;
                }
              }
            }
          }
          &:last-child {
            .box {
              .img {
                margin: 0 auto;
                background: url(../../../../assets/img/Core-functions_004.png);
                background-size: 100% 100%;
              }
              &:hover {
                border-color: none;
                .img {
                  background: url(../../../../assets/img/Core-functions_003.png);
                  background-size: 100% 100%;
                }
              }
            }
          }
        }
      }
    }
  }
  .Power {
    padding: 7rem 0;
    .inner {
      margin-bottom: 0;
      img {
        width: 100%;
      }
    }
  }
  .introduce {
    background: #f3f9ff;
    .inner {
      margin-bottom: 0;
      padding: 7rem 0;
      img {
        width: 100%;
      }
    }
  }
}
@media screen and(max-width:996px) {
  .content {
    .inner {
      padding: 0 2rem;
      .service {
        display: block;
        .item {
          width: 100%;
          border-radius: 0.2rem;
          .bottom {
            border-radius: 0.2rem;
          }
        }
      }
      .goods {
        display: block;
        .item {
          margin-bottom: 1.5rem;
          width: 100%;
          display: flex;
          align-items: stretch;
          padding: 0.6rem 0;
          .top {
            width: 50%;
            height: auto;
            img {
            }
          }
          .bot {
            padding: 1rem;
            width: 50%;
            height: auto;
            box-sizing: border-box;
            box-shadow: none;
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
          }
          &:nth-of-type(2),
          &:nth-of-type(4),
          &:nth-of-type(6) {
            flex-direction: row-reverse;
          }
          // &:hover {
          //   margin-top: 0;
          //   .top {
          //     width: auto;
          //     img {
          //       transform: none;
          //     }
          //   }
          //   .bot {
          //     box-shadow: 1px 1px 25px 5px #f7fafe;
          //   }
          // }
        }
      }
    }
    .fast {
      padding: 2rem;
      .inner {
        .fast-inner {
          flex-wrap: wrap;
          width: 100%;
          .item {
            margin: 1.5rem 0;
            width: 100%;
            .box {
              padding: 0.4rem 0.8rem;
            }
          }
        }
      }
    }
  }
}
</style>
